//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LeftBar from "@/components/left-bar/left-bar.vue";
import AboutBoard from "@/components/aboutBoard/index.vue";
import aboutApi from "@/api/about";
export default {
  components: {
    AboutBoard,
    LeftBar,
  },
  data() {
    return {
      action: {
        barShow: false,
      },
      companyHtml: null,
      funcHtml: null,
      currentMenuIndex: null,
      menulist: ["平台介绍", "联系我们"],
    };
  },
  mounted() {
    this.$store.commit("setNav", 1);
    this.action.barShow = true;
    this.checkMenu(this.menulist[0])
  },
  methods: {
    companyEnter(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },

    funcEnter(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    barAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    checkMenu(e) {
      this.currentMenuIndex = e;
      let that = this;
      if (e == "平台介绍") {
        aboutApi.getCompany().then((res) => {
          if (res) {
            that.companyHtml = res[0].content;
          }
        });
      } else if (e == "联系我们") {
        aboutApi.getWebsite().then((res) => {
          if (res) {
            that.funcHtml = res[0].content;
          }
        });
      }
    },
  },
};
