//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["title", "html"],
  mounted(){
    console.log("html",this.html);
  }
};
